import React from "react"
import styled from "styled-components"
import FloatingBlock from "../../elements/FloatingBlock"
import H3 from "../../elements/H3"
import ContentBase from "../../layout/ContentBase"
import Button from "../../elements/Button"

import MediaBackground from "./MediaBackground"
import mediaBtmBkg from "../../../images/media-btm-bkg.svg"
import mediaBtnBkgDesktop from "../../../images/media-btm-bkg-desktop.svg"

const AsideMedia = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 2rem;

  @media (min-width: 48rem) {
    display: grid;
    justify-items: center;
  }
`

const CurveBackground = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2rem;

  ${props =>
    props.isDesktop
      ? `
    display: none;
    @media (min-width: 64rem) {
      display: block;
    }`
      : `
      @media (min-width: 64rem) {
        display: none;
      }
    `}
`

const IframeContainer = styled.div`
  position: relative;
  width: 100%;
  /* height: 0; */
  padding-bottom: 56.25%;
  border-radius: 2px;
  overflow: hidden;

  @media (min-width: 48rem) {
    margin-right: 1rem;
  }

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 48rem) {
    display: grid;
    grid-template-columns: auto 10.125rem;
    grid-column-gap: 1.5rem;
  }

  @media (min-width: 64rem) {
    margin: 1.25rem;
  }
`
const ContentWrapper = styled.div`
  > h3 {
    color: #fff;
    margin-bottom: 1.125rem;

    @media (min-width: 64rem) {
      margin-bottom: 2.25rem;
    }
  }

  @media (min-width: 48rem) {
    position: relative;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  padding-right: 0.625rem;

  @media (min-width: 48rem) {
    margin-top: 2.5rem;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: 64rem) {
    margin-top: 3.875rem;
  }
`

const Spacer = styled.div`
  height: 1px;
  width: 1.5rem;

  @media (min-width: 48rem) {
    height: 1.5rem;
    width: 1px;
  }
`

const BlockWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (min-width: 64rem) {
    max-width: 42em;
  }
`

export default () => (
  <AsideMedia>
    <CurveBackground src={mediaBtmBkg} alt="media curve background" />
    <CurveBackground
      src={mediaBtnBkgDesktop}
      alt="media curve background"
      isDesktop
    />
    <ContentBase style={{ marginTop: "1rem" }}>
      <BlockWrapper>
        <FloatingBlock
          flatBorderColor
          borderSide="right"
          featured
          style={{ width: "100%" }}
        >
          <MediaBackground />
          <Container>
            <ContentWrapper>
              <H3>Media</H3>
              <IframeContainer>
                <iframe
                  title="myVideo"
                  src="https://www.youtube.com/embed/XnYaC5Qflvg"
                  frameBorder="0"
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </IframeContainer>
            </ContentWrapper>
            <ButtonWrapper>
              <a
                href="https://www.youtube.com/channel/UCnn3N0i3TVGbQ9bMb4ln2OQ?sub_confirmation=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button light>Subscribe</Button>
              </a>
              <Spacer />
              <a
                href="https://www.youtube.com/channel/UCnn3N0i3TVGbQ9bMb4ln2OQ/videos"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button light>See All</Button>
              </a>
            </ButtonWrapper>
          </Container>
        </FloatingBlock>
      </BlockWrapper>
    </ContentBase>
  </AsideMedia>
)
