import React, { useEffect } from "react"
import Layout from "../components/layout"
import SectionHero from "../components/blocks/hero/SectionHero"
import SectionWork from "../components/blocks/work/SectionWork"
import SectionAbout from "../components/blocks/about/SectionAbout"
import AsideMedia from "../components/blocks/media/AsideMedia"
import SectionProjects from "../components/blocks/projects/SectionProjects"
import SectionContact from "../components/blocks/contact/SectionContact"
import { Context } from "../context"

const IndexPage = () => {
  const observerCallback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("visible")
      }
    })
  }

  const options = { rootMargin: "-20%" }

  const observer =
    typeof window !== "undefined"
      ? new window.IntersectionObserver(observerCallback, options)
      : "empty"

  return (
    <Context.Provider value={observer}>
      <Layout>
        <SectionHero />
        <SectionWork />
        <SectionAbout />
        <AsideMedia />
        <SectionProjects />
        <SectionContact />
      </Layout>
    </Context.Provider>
  )
}

export default IndexPage
