import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const IconImage = styled.img`
  width: 2rem;
  margin-right: 1rem;
  height: 2rem;
`

export default ({ icon, iconAltText, children }) => (
  <Container>
    <IconImage src={icon} alt={iconAltText} />
    <div>{children}</div>
  </Container>
)
