import React from "react"
import styled from "styled-components"

import rocketLineImg from "../../../images/work-rocketbkg.svg"

const Img = styled.img`
  width: 12.125rem;
  position: absolute;
  right: 3.5rem;
  top: 4rem;
  z-index: -1;

  @media (min-width: 48rem) {
    top: 3rem;
    right: 4rem;
  }

  @media (min-width: 64rem) {
    top: 3rem;
    right: 13.25rem;
  }
`

export default () => <Img src={rocketLineImg} alt="rocket line" />
