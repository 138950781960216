import styled from "styled-components"
import Constants from "../../constants"

export default styled.h2`
  font-family: "Work Sans";
  font-weight: bold;
  font-size: 1.625‬em;
  color: ${Constants.Colors.keBlack};

  & span {
    color: ${Constants.Colors.keBasePurple};
  }

  @media (min-width: 48rem) {
    font-size: 2.25em;
    line-height: 1.125em;
  }
`
