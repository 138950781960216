import React from "react"
import styled, { keyframes } from "styled-components"

const scrollerAnim = keyframes`
  0% {
    stroke-dashoffset: 40;
  }
  100% {
    stroke-dashoffset: -40;
  }
`

const Svg = styled.svg`
  width: 3rem;
  position: relative;
`

const Path = styled.path`
  stroke-dasharray: 20 60;
  stroke-dashoffset: 40;

  animation: ${scrollerAnim} 1.5s infinite ease-out;
`

export default () => (
  <Svg
    viewBox="0 0 50 74"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="1.5"
  >
    <path
      d="M187.5 2162.21c0-4.42-3.585-8.01-8-8.01h-30.875c-4.415 0-8 3.59-8 8.01v54.3c0 4.42 3.585 8 8 8H179.5c4.415 0 8-3.58 8-8v-54.3z"
      fill="#fff"
      stroke="#898cbd"
      strokeWidth="3"
      transform="matrix(1 0 0 .99897 -139.125 -2150.49)"
    />
    <Path
      d="M24.937 15.767V55.2"
      fill="none"
      stroke="#352944"
      strokeWidth="3"
    />
  </Svg>
)
