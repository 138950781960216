import React from "react"
import styled from "styled-components"
import Constants from "../../../constants"
import backgroundImg from "../../../images/hero-bkg.svg"
import backgroundDesktopImg from "../../../images/hero-bkg-desktop.svg"
import backgroundLines from "../../../images/hero-bkg-linework.svg"
import BkgCurveImg from "./BkgCurveImg"
import ContentBase from "../../layout/ContentBase"
import H1 from "../../elements/H1"
import Section from "../../elements/Section"
import ImgHeroHeadshot from "./ImgHeroHeadshot"
import Scroller from "./Scroller"

const HeroDescription = styled.p`
  text-align: center;
  font-size: 1.125em;
  color: ${Constants.Colors.keTextPurple};
  margin-bottom: 5rem;

  @media (min-width: 48rem) {
    max-width: 18rem;
    margin-bottom: 7rem;
  }
`

const BkgLinework = styled.img`
  position: absolute;
  z-index: -1;
  width: 11.5rem;
  top: 14.5rem;
`

const SectionHero = () => (
  <Section style={{ minHeight: "100vh", alignItems: "center" }}>
    <BkgCurveImg src={backgroundImg} alt="background hero section" />
    <BkgCurveImg
      alt="background hero section"
      src={backgroundDesktopImg}
      isDesktop
    />

    <ContentBase style={{ marginTop: "4rem" }}>
      <H1>
        <small>I build </small>
        <br />
        experiences
      </H1>
      <ImgHeroHeadshot />
      <BkgLinework src={backgroundLines} alt="background lines" />
      <HeroDescription>
        Blending artistic expression and technology to create software people
        love using
      </HeroDescription>
      <Scroller />
    </ContentBase>
  </Section>
)

export default SectionHero
