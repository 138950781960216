import styled from "styled-components"
import Constants from "../../constants"

export default styled.h4`
  font-size: 1.125em;
  font-family: "Work Sans";
  font-weight: bold;
  color: ${Constants.Colors.keTextPurple};
  margin-bottom: 0.3rem;
`
