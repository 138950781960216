import React from "react"
import styled from "styled-components"

import mediaBkgCurveImg from "../../../images/media-bkg-curve.svg"
import mediaBkgSquareImg from "../../../images/media-bkg-square.svg"
import mediaBkgCImg from "../../../images/media-bkg-c.svg"

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const MediaBackground = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media (min-width: 48rem) {
    bottom: -8rem;
  }
`

const MediaBackgroundSquare = styled.img`
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1.125rem;
  right: 6rem;
`

const MediaBackgroundC = styled.img`
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 2.25rem;
  right: 2rem;
`

export default () => (
  <Wrapper>
    <MediaBackground src={mediaBkgCurveImg} alt="featured background" />
    <MediaBackgroundSquare
      src={mediaBkgSquareImg}
      alt="featured accent square"
    />
    <MediaBackgroundC src={mediaBkgCImg} alt="featured accent c" />
  </Wrapper>
)
