import styled from "styled-components"
import Constants from "../../constants"

export default styled.div`
  position: absolute;
  top: -1px;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, ${Constants.Colors.keBkgGrey}, #fff);
`
