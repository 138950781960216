import styled from "styled-components"
import Constants from "../../constants"

export default styled.p`
  font-size: 1em;
  font-weight: 300;
  line-height: 1.3;
  color: ${Constants.Colors.keTextPurple};
  ${props => props.marginBottom && `margin-bottom: 1.125em;`}
  > a {
    color: ${Constants.Colors.keBasePurple};
    font-weight: bold;
    text-decoration: none;
  }
`
