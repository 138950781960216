import styled from "styled-components"
import Constants from "../../constants"

export default styled.h3`
  font-family: "Work Sans";
  font-weight: bold;
  font-size: 1.25em;
  color: ${Constants.Colors.keBlack};
  margin-bottom: 0.75rem;

  & span {
    color: ${Constants.Colors.keBasePurple};
  }

  @media (min-width: 64rem) {
    font-size: 1.5em;
  }
`
