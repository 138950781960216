import React from "react"
import styled from "styled-components"

import imgPhone from "../../../images/work-phone.svg"
import imgPhonePad from "../../../images/work-phonepad.svg"

const PhoneImg = styled.img`
  width: 19rem;
  z-index: 2;
  position: absolute;
  filter: drop-shadow(0 0 2.5rem rgba(0, 115, 255, 0.25));
`

const PhonePadImg = styled.img`
  width: 11rem;
  z-index: 3;
  position: relative;
  bottom: 1em;
  filter: drop-shadow(0 5px 0.875rem rgba(8, 0, 150, 0.18));
`

const Div = styled.div`
  width: 100%;
  height: 4.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 3.75rem;

  @media (min-width: 48rem) {
    position: absolute;
    right: 0;
    top: 12rem;
    width: 18rem;
    margin: 0;
  }

  @media (min-width: 64rem) {
    right: 8.5rem;
    top: 12rem;
    width: 20rem;
    margin: 0;
  }
`

export default () => (
  <Div>
    <PhonePadImg src={imgPhonePad} alt="phone launch pad" />
    <PhoneImg src={imgPhone} alt="phone" />
  </Div>
)
