import React from "react"
import styled from "styled-components"

import Constants from "../../constants"

const Wrapper = styled.div`
  position: relative;
`

const ButtonInput = styled.button`
  border: none;
  background: ${props =>
    props.light ? "#FFF" : Constants.Colors.keButtonPurple};
  color: ${props => (props.light ? Constants.Colors.keTextPurple : "#FFF")};
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1.125rem 1rem;
  min-width: 7.375rem;
  border-radius: 2px;
  position: relative;
  z-index: 1;
  cursor: pointer;
`

const Border = styled.div`
  border-radius: 2px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0.5rem;
  left: 0.5rem;

  ${props =>
    props.light
      ? `
    border: 3px solid ${Constants.Colors.keTextPurple};
    background: transparent;
    `
      : `
    background: #fff;
    background-clip: padding-box;
    border: solid 3px transparent;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -3px;
      border-radius: inherit;
      background: linear-gradient(to right, rgb(66, 240, 234), rgb(141, 72, 232));
    }
    `}
`

export default ({ light, onClick, children, ...props }) => (
  <Wrapper>
    <ButtonInput light={light} type="button" onClick={onClick} {...props}>
      {children}
    </ButtonInput>
    <Border light={light} />
  </Wrapper>
)
