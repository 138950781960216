import React from "react"
import styled from "styled-components"

import FloatingBlock from "../../elements/FloatingBlock"

import featuredBackgroundImg from "../../../images/contact-featured-curve.svg"
import featuredBackgroundC from "../../../images/contact-featured-c.svg"
import featuredBackgroundSquare from "../../../images/contact-featured-square.svg"
import featuredBackgroundX from "../../../images/contact-featured-x.svg"

import iconMedium from "../../../images/contact-icon-medium.svg"
import iconYoutube from "../../../images/contact-icon-youtube.svg"
import iconDribbble from "../../../images/contact-icon-dribbble.svg"
import iconLinkedin from "../../../images/contact-icon-linkedin.svg"
import H3 from "../../elements/H3"
import BodyText from "../../elements/BodyText"

const Wrapper = styled.div`
  position: relative;
  margin-top: 3rem;
  display: flex;
  z-index: 2;

  @media (min-width: 48rem) {
    max-width: 27rem;
    align-self: flex-end;
    margin-top: 0;
  }
`

const FeaturedBkgCurve = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`

const FeaturedBkgC = styled.img`
  position: absolute;
  width: 0.75rem;
  top: 1.125rem;
  right: 5rem;
`

const FeaturedBkgSquare = styled.img`
  position: absolute;
  width: 1.125rem;
  top: 1.5rem;
  right: 2.25rem;
`

const FeaturedBkgX = styled.img`
  position: absolute;
  width: 0.75rem;
  top: 4.75rem;
  right: 3rem;
`

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 2.5rem);
  grid-column-gap: 2rem;
`

const Icon = styled.img``

export default () => (
  <Wrapper>
    <FloatingBlock
      featured
      flatBorderColor
      borderSide="right"
      style={{ marginTop: "3rem" }}
    >
      <FeaturedBkgCurve
        src={featuredBackgroundImg}
        alt="featured background curve"
      />
      <FeaturedBkgC src={featuredBackgroundC} alt="featured background c" />
      <FeaturedBkgSquare
        src={featuredBackgroundSquare}
        alt="featured background square"
      />
      <FeaturedBkgX src={featuredBackgroundX} alt="featured background x" />
      <H3 style={{ color: "#FFF" }}>Around the web</H3>
      <BodyText
        style={{ color: "rgba(255,255,255, 0.6)", marginBottom: "1rem" }}
      >
        Check out some places I post my work and content.{" "}
      </BodyText>
      <IconGrid>
        <a
          href="https://medium.com/@kyle_erickson"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={iconMedium} alt="medium icon" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCnn3N0i3TVGbQ9bMb4ln2OQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={iconYoutube} alt="youtube icon" />
        </a>
        <a
          href="https://dribbble.com/kyle_erickson"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={iconDribbble} alt="dribbble icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/kyle-erickson/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={iconLinkedin} alt="linkedin icon" />
        </a>
      </IconGrid>
    </FloatingBlock>
  </Wrapper>
)
