import React from "react"
import styled from "styled-components"

import keHeadshot from "../../../images/ke-headshot@1x.png"
import bkgCurve from "../../../images/hero-bkg-curve.svg"

import bkgTriangle from "../../../images/hero-bkg-triangle.svg"
import bkgCircle from "../../../images/hero-bkg-circle.svg"
import bkgCross from "../../../images/hero-bkg-cross.svg"

const Frame = styled.div`
  width: 16rem;
  height: 13.1rem;
  border-radius: 2px;
  position: relative;
  z-index: 10;
  margin-bottom: 2rem;
  margin-top: 2rem;
  /* px + (translateZ * -px) / perspective */
`

const BaseView = styled.div`
  background: linear-gradient(135deg, #4de1ea, #8d58e8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 0 1.875rem rgba(0, 113, 255, 0.3);
  margin-bottom: 0.625rem;
  margin-right: 0.625rem;
`

const BaseViewCurveBkg = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`

const BaseViewHeadshot = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 12rem;
`

const BaseViewCross = styled.img`
  width: 0.7625rem;
  position: absolute;
  left: 5.625rem;
  top: 5.9375rem;
`

const BaseViewTriangle = styled.img`
  width: 2rem;
  position: absolute;
  left: 3.1875rem;
  top: 1.125em;
`

const BaseViewCircle = styled.img`
  width: 1.25rem;
  position: absolute;
  left: 2rem;
  top: 6.75em;
`

const DivBorder = styled.div`
  border-radius: 2px;
  background: #fff;
  background-clip: padding-box;
  border: solid 3px transparent;
  position: absolute;
  top: 0.625rem;
  bottom: 0;
  left: 0.625rem;
  right: 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -3px;
    border-radius: inherit;
    background: linear-gradient(to right, rgb(66, 240, 234), rgb(141, 72, 232));
  }
`

export default () => (
  <Frame>
    <DivBorder />
    <BaseView>
      <BaseViewCurveBkg src={bkgCurve} alt="curve background" />
      <BaseViewHeadshot src={keHeadshot} alt="headshot" />
      <BaseViewCross src={bkgCross} alt="background cross" />
      <BaseViewTriangle src={bkgTriangle} alt="background triangle" />
      <BaseViewCircle src={bkgCircle} alt="background circle" />
    </BaseView>
  </Frame>
)
