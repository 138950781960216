import React from "react"
import styled from "styled-components"

import Section from "../../elements/Section"
import BackgroundGradient from "../../elements/BackgroundGradient"
import ContentBase from "../../layout/ContentBase"
import H2 from "../../elements/H2"
import BodyText from "../../elements/BodyText"

import ImgRocket from "./ImgRocket"
import ImgRocketLine from "./ImgRocketLine"
import DivPhone from "./DivPhone"
import BkgCurveImg from "./BkgCurveImg"
import WorkBlock from "./WorkBlock"

import iconFrontend from "../../../images/work-icon-frontend.svg"
import iconApps from "../../../images/work-icon-apps.svg"
import iconDesign from "../../../images/work-icon-design.svg"
import backgroundImg from "../../../images/work-btm-bkg.svg"
import backgroundDesktopImg from "../../../images/work-btm-bkg-desktop.svg"

const DescriptionWrapper = styled.div`
  @media (min-width: 48rem) {
    max-width: 18rem;
    margin-bottom: 7rem;
  }
  @media (min-width: 64rem) {
    max-width: 20rem;
  }
`

const WorkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media (min-width: 64rem) {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: auto;
  }
`

export default () => (
  <Section>
    <BackgroundGradient top="0" />
    <BkgCurveImg src={backgroundImg} alt="background hero section" />
    <BkgCurveImg
      src={backgroundDesktopImg}
      alt="background hero section"
      isDesktop
    />
    <ContentBase leftAligned>
      <ImgRocket />
      <ImgRocketLine />
      <H2>
        Design + <br />
        Develop + <br />
        <span>Deploy</span>
      </H2>
      <DescriptionWrapper>
        <BodyText>
          Working with people, applying creative solutions to challenging and
          complex problems.
        </BodyText>
      </DescriptionWrapper>
      <DivPhone />
      <WorkWrapper>
        <WorkBlock
          borderSide="right"
          icon={iconFrontend}
          iconAltText="front end icon"
          title="Frontend Development"
          bodyText="JavaScript, React, CSS3, Motion Design"
        />
        <WorkBlock
          borderSide="left"
          icon={iconApps}
          iconAltText="apps icon"
          title="Apps"
          bodyText="Mobile (iOS/Android), React Native, Swift"
        />
        <WorkBlock
          lastItem
          borderSide="right"
          icon={iconDesign}
          iconAltText="design icon"
          title="Design"
          bodyText="Illustration, User Interface, Design Systems"
        />
      </WorkWrapper>
    </ContentBase>
  </Section>
)
