import React from "react"
import styled from "styled-components"

import Section from "../../elements/Section"
import BackgroundGradient from "../../elements/BackgroundGradient"
import H2 from "../../elements/H2"
import BodyText from "../../elements/BodyText"
import Button from "../../elements/Button"
import ContentBase from "../../layout/ContentBase"
import MediaBlock from "./MediaBlock"

import airplaneImg from "../../../images/contact-airplane.svg"
import dottedLine from "../../../images/contact-dotted-line.svg"
import dottedLineIpad from "../../../images/contact-dotted-line-ipad.svg"
import dottedLineDesktop from "../../../images/contact-dotted-line-desktop.svg"

const PaperAirplane = styled.img`
  width: 6.5rem;
  position: absolute;
  right: 0;
  top: 4rem;

  @media (min-width: 48rem) {
    top: 2rem;
  }
`

const DottedLine = styled.img`
  position: absolute;
  width: 10.375rem;
  right: 1.125rem;
  top: 5.125rem;

  @media (min-width: 48rem) {
    display: none;
  }
`

const DottedLineIpad = styled.img`
  display: none;

  @media (min-width: 48rem) and (max-width: 63.999rem) {
    display: block;
    position: absolute;
    width: 17.75rem;
    right: 5.125rem;
    top: 4.125rem;
  }
`

const DottedLineDesktop = styled.img`
  display: none;

  @media (min-width: 64rem) {
    display: block;
    position: absolute;
    width: 32.75rem;
    right: 6.125rem;
    top: 4.125rem;
  }
`

export default () => (
  <Section>
    <BackgroundGradient top="calc(6rem - 3px)" />
    <ContentBase leftAligned>
      <H2>
        Get in <span>touch</span>
      </H2>
      <BodyText style={{ marginBottom: "1rem" }}>
        Want to grab a coffee?
      </BodyText>
      <a href="mailto:hello@kyle-erickson.com">
        <Button>Say Hello</Button>
      </a>
      <DottedLine src={dottedLine} alt="flight path" />
      <DottedLineIpad src={dottedLineIpad} alt="flight path" />
      <DottedLineDesktop src={dottedLineDesktop} alt="flight path" />
      <PaperAirplane src={airplaneImg} alt="paper airplane" />
      <MediaBlock />

      <footer style={{ textAlign: "center", width: "100%" }}>
        <BodyText style={{ opacity: 0.5, marginTop: "2rem" }}>
          © {new Date().getFullYear()}, Kyle Erickson
        </BodyText>
      </footer>
    </ContentBase>
  </Section>
)
