import React from "react"
import styled from "styled-components"

import ContentBase from "../../layout/ContentBase"
import Section from "../../elements/Section"
import BackgroundGradient from "../../elements/BackgroundGradient"
import H2 from "../../elements/H2"

import MapGraphic from "./map-graphic/MapGraphic"
import BodyText from "../../elements/BodyText"

const ContentWrapper = styled.div`
  @media (min-width: 48rem) {
    max-width: 22rem;
  }

  @media (min-width: 64rem) {
    max-width: 32rem;
    margin-bottom: 2rem;
  }
`

export default () => (
  <Section>
    <BackgroundGradient top="2.75rem" />
    <ContentBase leftAligned>
      <H2>
        About <span>Me</span>
      </H2>
      <MapGraphic />
      <ContentWrapper>
        <BodyText marginBottom>
          Hello! I'm Kyle, a designer and software developer from Canada. I love
          building web and mobile experiences with clean, user-friendly user
          interfaces, smooth motion and tasteful animation.
        </BodyText>
        <BodyText marginBottom>
          I started my development journey at the{" "}
          <a
            href="https://www.nait.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            Northern Alberta Institute of Technology,{" "}
          </a>
          and have since worked with a number of great people, like the team I
          currently work with at{" "}
          <a
            href="http://www.inputlogic.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Input Logic
          </a>
          .
        </BodyText>
        <BodyText marginBottom>
          I've worked on a wide variety of different projects, all with their
          own unique purpose, use or message, using many different tools and
          technologies.
        </BodyText>
        <BodyText marginBottom>
          In my spare time I enjoy photography, content creation, sports &
          fitness, and learning about business and leadership.
        </BodyText>
      </ContentWrapper>
    </ContentBase>
  </Section>
)
