import React from "react"
import styled from "styled-components"

import FloatingBlock from "../../elements/FloatingBlock"
import DivIconText from "./DivIconText"
import H4 from "../../elements/H4"
import BodyText from "../../elements/BodyText"

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 2.25rem;
  display: flex;
  ${props =>
    props.borderSide === "right" ? "right: 0.5rem;" : "left: 0.5rem;"}

  @media(min-width: 48rem) {
    max-width: 20rem;
    ${props => props.borderSide === "left" && "align-self: flex-end;"}
    ${props => props.borderSide === "right" && "right: 0;"}
  }

  @media (min-width: 64rem) {
    max-width: 22em;
    ${props => props.borderSide === "left" && "justify-self: end;"}
    ${props =>
      props.lastItem &&
      `
      grid-column-start: 1;
      grid-column-end: span 2;
      justify-self: center;
    `}
  }
`

export default ({
  borderSide,
  lastItem,
  icon,
  iconAltText,
  title,
  bodyText,
}) => (
  <Wrapper borderSide={borderSide} lastItem={lastItem}>
    <FloatingBlock borderSide={borderSide} lastItem={lastItem}>
      <DivIconText icon={icon} iconAltText={iconAltText}>
        <H4>{title}</H4>
        <BodyText>{bodyText}</BodyText>
      </DivIconText>
    </FloatingBlock>
  </Wrapper>
)
