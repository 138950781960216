import styled from "styled-components"

export default styled.img`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;

  ${props =>
    props.isDesktop
      ? `
    display: none;
    @media (min-width: 64rem) {
      display: block;
    }`
      : `
      @media (min-width: 64rem) {
        display: none;
      }
    `}
`
