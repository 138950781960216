import React from "react"
import styled from "styled-components"

import ContentBase from "../../layout/ContentBase"
import Section from "../../elements/Section"
import BackgroundGradient from "../../elements/BackgroundGradient"
import H2 from "../../elements/H2"
import DivProject from "./DivProject"

import projectPersonalBkgImg from "../../../images/projects-personal-bkg.svg"
import projectBtmBkgImg from "../../../images/projects-btm-bkg.svg"
import projectBtmBkgImgDesktop from "../../../images/projects-btm-bkg-desktop.svg"
import projectYardlyPreview from "../../../images/projects-yardly-view.jpg"
import projectNightriderPreview from "../../../images/projects-nightrider-view.jpg"
import projectGoosePreview from "../../../images/projects-goose-view.jpg"

const BackgroundCurve = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  ${props =>
    props.isDesktop
      ? `
    display: none;
    @media (min-width: 64rem) {
      display: block;
    }`
      : `
      @media (min-width: 64rem) {
        display: none;
      }
    `}
`

export default () => (
  <Section>
    <BackgroundGradient />
    <BackgroundCurve
      src={projectBtmBkgImgDesktop}
      alt="project background curve"
      isDesktop
    />
    <BackgroundCurve src={projectBtmBkgImg} alt="project background curve" />
    <ContentBase leftAligned>
      <H2>Projects</H2>
      <DivProject
        borderSide="right"
        image={projectGoosePreview}
        projectType="Featured Project"
        projectTitle="Goose Insurance"
        projectTags={[
          "react-native",
          "redux",
          "push-notifications",
          "geofencing",
          "stripe",
          "apple-pay",
          "g-pay",
        ]}
      >
        A cross-platform application for purchasing travel insurance, getting
        emergency/medical information for your current location, and receiving
        reminders when nearing an airport to purchase insurance.
      </DivProject>
      <DivProject
        borderSide="left"
        image={projectYardlyPreview}
        projectType="Featured Project"
        projectTitle="Yardly"
        projectTags={[
          "react-native",
          "reactjs",
          "redux",
          "maps",
          "sass-css",
          "svg-animation",
          "ux",
          "design",
        ]}
      >
        A customer-facing, cross-platform mobile & web application that
        customers can order services from. Pricing is based on user input and
        selection, and services can be puchased in-app through a payment API.
      </DivProject>
      <DivProject
        borderSide="right"
        image={projectNightriderPreview}
        projectType="Personal Project"
        projectTitle="Night Rider"
        projectTags={["vs-code"]}
        backgroundImage={projectPersonalBkgImg}
        flatBorderColor
        featured
        lastItem
      >
        A electrifying, vibrant color theme for Visual Studio Code.
      </DivProject>
    </ContentBase>
  </Section>
)
