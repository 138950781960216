import React from "react"
import styled from "styled-components"
import H3 from "../../elements/H3"
import FloatingBlock from "../../elements/FloatingBlock"

import Constants from "../../../constants"
import BodyText from "../../elements/BodyText"

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 64rem) {
    height: 28.125rem;
    margin-bottom: ${props => (props.lastItem ? "0" : "6rem")};
  }
`

const PreviewImage = styled.div`
  display: none;

  @media (min-width: 64rem) {
    display: block;
    width: 37.5rem;
    height: 28.125rem;
    overflow: hidden;
    border-radius: 2px;
    position: absolute;
    ${props => (props.borderSide === "right" ? "right: 0;" : "left: 0;")}
    background: url(${props => props.image});
    background-size: cover;
    z-index: -1;
  }
`

const BoxWrapper = styled.div`
  position: relative;
  margin-bottom: 2.5rem;
  display: flex;
  ${props =>
    props.borderSide === "right" ? "right: 0.5rem;" : "left: 0.5rem;"}

  @media(min-width: 48rem) {
    max-width: 27rem;
    ${props => props.borderSide === "left" && "align-self: flex-end;"}
    ${props => props.borderSide === "right" && "right: 0;"}
  }

  @media (min-width: 64rem) {
    max-width: 36rem;
    top: 1rem;
  }
`

const ProjectTypeText = styled.p`
  font-size: 0.875em;
  font-family: "Work Sans";
  font-weight: bold;
  color: ${Constants.Colors.keBaseTeal};
  margin-bottom: 0.3rem;
`

const ProjectBackground = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

const PillWrapper = styled.ul`
  list-style-type: none;
  margin: 1rem 0 0 0;
`

const Pill = styled.li`
  display: inline-block;
  margin-right: 0.375rem;

  > p {
    display: block;
    font-size: 0.875em;
    padding: 0.25rem 0.75rem;
    background: ${Constants.Colors.keBkgPurple};
    border-radius: 0.975rem;
    font-family: "Work Sans";
    font-weight: bold;
    color: ${Constants.Colors.keBasePurple};
  }
`

export default ({
  projectType,
  projectTitle,
  children,
  projectTags,
  borderSide,
  backgroundImage,
  flatBorderColor,
  featured,
  image,
  lastItem,
  ...props
}) => (
  <Wrapper lastItem={lastItem}>
    <PreviewImage image={image} borderSide={borderSide} />
    <BoxWrapper borderSide={borderSide}>
      <FloatingBlock
        borderSide={borderSide}
        flatBorderColor={flatBorderColor}
        featured={featured}
        {...props}
      >
        {backgroundImage && (
          <ProjectBackground src={backgroundImage} alt="project background" />
        )}
        <ProjectTypeText>{projectType}</ProjectTypeText>
        <H3
          style={{ color: featured ? "#FFF" : Constants.Colors.keTextPurple }}
        >
          {projectTitle}
        </H3>
        <BodyText style={featured && { color: "rgba(255,255,255, 0.6)" }}>
          {children}
        </BodyText>
        <PillWrapper>
          {projectTags.map((value, index) => (
            <Pill key={`${projectTitle}-${index}`}>
              <p>{value}</p>
            </Pill>
          ))}
        </PillWrapper>
      </FloatingBlock>
    </BoxWrapper>
  </Wrapper>
)
