import styled from "styled-components"

export default styled.section`
  position: relative;

  @media (min-width: 48rem) {
    display: grid;
    justify-items: center;
  }
`
