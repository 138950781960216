import React from "react"
import styled from "styled-components"

import rocketImg from "../../../images/work-rocketship.svg"

const Img = styled.img`
  width: 4.125rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;

  @media (min-width: 48rem) {
    right: 2rem;
  }

  @media (min-width: 64rem) {
    right: 11rem;
  }
`

export default () => <Img src={rocketImg} alt="rocket ship" />
