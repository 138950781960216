import React from "react"
import styled from "styled-components"

import Constants from "../../../../constants"

import locationImg from "../../../../images/about-location.svg"
import locationDashImg from "../../../../images/about-location-dash.svg"

import BodyText from "../../../elements/BodyText"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;

  @media (min-width: 48rem) {
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 4rem;
  }
`

const MapImg = styled.img`
  width: 5.625rem;

  @media (min-width: 48rem) {
    width: 9.375rem;
  }

  @media (min-width: 64rem) {
    width: 14.375rem;
  }
`

const MapDashImg = styled.img`
  width: 6.25rem;
  position: relative;
  right: 0.875rem;
  top: 0.5rem;

  @media (min-width: 48rem) {
    left: 3.325rem;
    top: 1.25rem;
    transform: rotate(90deg);
  }

  @media (min-width: 64rem) {
    top: 1rem;
    left: 6.75rem;
  }
`

const TextWrapper = styled.div`
  top: 1.75rem;
  right: 0.5rem;
  position: relative;

  > p {
    color: ${Constants.Colors.keTextPurpleFaint};
  }

  @media (min-width: 48rem) {
    text-align: center;
    top: 4.5rem;
    right: 0;
    left: 1.5rem;
  }
  @media (min-width: 64rem) {
    left: 2.5rem;
  }
`

export default () => (
  <Container>
    <MapImg src={locationImg} alt="my location" />
    <MapDashImg src={locationDashImg} alt="location path" />
    <TextWrapper>
      <BodyText>Halifax, NS</BodyText>
    </TextWrapper>
  </Container>
)
