import React, { useState, useEffect, useContext, useRef } from "react"
import styled, { keyframes } from "styled-components"

import Constants from "../../constants"
import { Context } from "../../context"

const springAnim = keyframes`
  0%{
    opacity: 0;
    transform: scale(0);
  }
  30%{
    transform: scale(0.3);
  }
  40%{
    transform: scale(0.5);
  }
  50%{
    transform: scale(0.7);
  }
  75%{
    transform: scale(1.1);
  }
  90%{
    transform: scale(1);
  }
  100%{
    opacity: 1;
  }
`

const DivContainer = styled.div`
  position: relative;
  width: calc(100% + 1rem);

  opacity: 0;
  transform: scale(0);

  &.visible {
    animation: 0.4s ${springAnim} ease-out;
    opacity: 1;
    transform: scale(1);
  }
`

const DivBorder = styled.div`
  border-radius: 2px;
  background: #fff;
  background-clip: padding-box;
  border: solid 3px transparent;
  position: absolute;
  top: 0.625rem;
  bottom: 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -3px;
    border-radius: inherit;
    background: ${props =>
      props.flatBorderColor
        ? Constants.Colors.keBasePurpleDull
        : `linear-gradient(to right, rgb(66, 240, 234), rgb(141, 72, 232))`};
  }
`

const DivContent = styled.div`
  background: ${props =>
    props.featured
      ? "linear-gradient(35deg, rgb(139, 92, 232), rgb(80, 219, 234))"
      : "#fff"};
  border-radius: 2px;
  box-shadow: 0 0 1.875rem rgba(1, 111, 255, 0.2);
  position: relative;
  z-index: 2;
  margin-bottom: 0.625rem;
  padding: 1.5rem;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in;

  &:hover {
    top: 0.375rem;
  }
`

const RightBorderContent = styled(DivContent)`
  margin-right: 0.625rem;
  &:hover {
    left: 0.375rem;
  }
`

const LeftBorderContent = styled(DivContent)`
  margin-left: 0.625rem;
  &:hover {
    left: -0.375rem;
  }
`

const RightBorder = styled(DivBorder)`
  left: 0.625rem;
  right: 0;
`

const LeftBorder = styled(DivBorder)`
  right: 0.625rem;
  left: 0;
`

const CenterBorder = styled(DivBorder)`
  right: 0.625rem;
  left: 0.625rem;
`

export default ({
  borderSide,
  flatBorderColor,
  featured,
  lastItem,
  children,
  ...props
}) => {
  const [width, setWidth] = useState(0)
  const [listenerRegistered, setListenerRegistered] = useState(false)
  const observer = useContext(Context)
  const refEle = useRef(null)

  useEffect(() => {
    if (!refEle.current) return
    if (!listenerRegistered) {
      const handleWindowSizeChange = () => {
        setWidth(window.innerWidth)
      }
      handleWindowSizeChange()
      window.addEventListener("resize", handleWindowSizeChange)
      setListenerRegistered(true)
    }
    if (observer !== "empty") observer.observe(refEle.current)
  }, [refEle.current])

  if (width >= 1024 && lastItem) {
    return (
      <DivContainer {...props} ref={refEle}>
        <React.Fragment>
          <DivContent featured={featured}>{children}</DivContent>
          <CenterBorder flatBorderColor={flatBorderColor} />
        </React.Fragment>
      </DivContainer>
    )
  } else {
    return (
      <DivContainer {...props} ref={refEle}>
        {borderSide === "right" && (
          <React.Fragment>
            <RightBorderContent featured={featured}>
              {children}
            </RightBorderContent>
            <RightBorder flatBorderColor={flatBorderColor} />
          </React.Fragment>
        )}
        {borderSide === "left" && (
          <React.Fragment>
            <LeftBorderContent featured={featured}>
              {children}
            </LeftBorderContent>
            <LeftBorder flatBorderColor={flatBorderColor} />
          </React.Fragment>
        )}
      </DivContainer>
    )
  }
}
